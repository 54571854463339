<template>
  <div style="padding:0 10px">
    <!-- <div
      class="top"
      style="width: 100%;height: 70px;line-height: 70px;background-color: #8e9090;color: #ffffff;font-size: 30px;padding: 0 30px;"
    >智荟网</div>-->
    <table style="width:100%;border:none;border-spacing:0px">
      <tr style="vertical-align:top">
        <td style="text-align:left;padding:5px">
          <p style="line-height: 1.80;" @click="Jump('知识头条')">
            <span
              class="f_WPSOfficeÊOe¶¯Ae¿Â¼1"
              style="font-family: Calibri,Vectora,'Droid Sans','Open Sans',Frutiger,sans-serif;"
            >
              <a class="topiclink">1.</a>
            </span>
            <span class="f_WPSOfficeÊOe¶¯Ae¿Â¼1" style="font-family: 宋体;">
              <a class="topiclink">知识头条</a>
            </span>
          </p>
          <p style="line-height: 1.80;" @click="Jump('如何发布文章')">
            <span
              class="f_WPSOfficeÊOe¶¯Ae¿Â¼2"
              style="font-family: Calibri,Vectora,'Droid Sans','Open Sans',Frutiger,sans-serif;"
            >
              <a class="topiclink">1.1.</a>
            </span>
            <span class="f_WPSOfficeÊOe¶¯Ae¿Â¼2" style="font-family: 宋体;">
              <a class="topiclink">如何文布文章</a>
            </span>
          </p>
          <p style="line-height: 1.80;" @click="Jump('如何发布快讯')">
            <span
              class="f_WPSOfficeÊOe¶¯Ae¿Â¼2"
              style="font-family: Calibri,Vectora,'Droid Sans','Open Sans',Frutiger,sans-serif;"
            >
              <a class="topiclink">1.2.</a>
            </span>
            <span class="f_WPSOfficeÊOe¶¯Ae¿Â¼2" style="font-family: 宋体;">
              <a class="topiclink">如何发布快讯</a>
            </span>
          </p>
          <p style="line-height: 1.80;" @click="Jump('智能问答')">
            <span
              class="f_WPSOfficeÊOe¶¯Ae¿Â¼1"
              style="font-family: Calibri,Vectora,'Droid Sans','Open Sans',Frutiger,sans-serif;"
            >
              <a class="topiclink">2.</a>
            </span>
            <span class="f_WPSOfficeÊOe¶¯Ae¿Â¼1" style="font-family: 宋体;">
              <a class="topiclink">智能问答</a>
            </span>
          </p>
          <p style="line-height: 1.80;" @click="Jump('如何发布问题')">
            <span
              class="f_WPSOfficeÊOe¶¯Ae¿Â¼2"
              style="font-family: Calibri,Vectora,'Droid Sans','Open Sans',Frutiger,sans-serif;"
            >
              <a class="topiclink">2.1.</a>
            </span>
            <span class="f_WPSOfficeÊOe¶¯Ae¿Â¼2" style="font-family: 宋体;">
              <a class="topiclink">如何发布问题</a>
            </span>
          </p>
          <p style="line-height: 1.80;" @click="Jump('如何回答问题')">
            <span
              class="f_WPSOfficeÊOe¶¯Ae¿Â¼2"
              style="font-family: Calibri,Vectora,'Droid Sans','Open Sans',Frutiger,sans-serif;"
            >
              <a class="topiclink">2.2.</a>
            </span>
            <span class="f_WPSOfficeÊOe¶¯Ae¿Â¼2" style="font-family: 宋体;">
              <a class="topiclink">如何回答问题</a>
            </span>
          </p>
          <p style="line-height: 1.80;" @click="Jump('如何引用回答')">
            <span
              class="f_WPSOfficeÊOe¶¯Ae¿Â¼3"
              style="font-family: Calibri,Vectora,'Droid Sans','Open Sans',Frutiger,sans-serif;"
            >
              <a class="topiclink">2.2.3.</a>
            </span>
            <span class="f_WPSOfficeÊOe¶¯Ae¿Â¼3" style="font-family: 宋体;">
              <a class="topiclink">如何引用回答</a>
            </span>
          </p>
          <p style="line-height: 1.80; " @click="Jump('如何采纳问题')">
            <span
              class="f_WPSOfficeÊOe¶¯Ae¿Â¼2"
              style="font-family: Calibri,Vectora,'Droid Sans','Open Sans',Frutiger,sans-serif;"
            >
              <a class="topiclink">2...</a>
            </span>
            <span class="f_WPSOfficeÊOe¶¯Ae¿Â¼2" style="font-family: 宋体;">
              <a class="topiclink">如何采纳问题</a>
            </span>
          </p>
          <p style="line-height: 1.80;" @click="Jump('资源交易')">
            <span
              class="f_WPSOfficeÊOe¶¯Ae¿Â¼1"
              style="font-family: Calibri,Vectora,'Droid Sans','Open Sans',Frutiger,sans-serif;"
            >
              <a class="topiclink">3.</a>
            </span>
            <span class="f_WPSOfficeÊOe¶¯Ae¿Â¼1" style="font-family: 宋体;">
              <a class="topiclink">资源交易</a>
            </span>
          </p>
          <p style="line-height: 1.80;" @click="Jump('如何发布资源')">
            <span
              class="f_WPSOfficeÊOe¶¯Ae¿Â¼2"
              style="font-family: Calibri,Vectora,'Droid Sans','Open Sans',Frutiger,sans-serif;"
            >
              <a class="topiclink">3.1.</a>
            </span>
            <span class="f_WPSOfficeÊOe¶¯Ae¿Â¼2" style="font-family: 宋体;">
              <a class="topiclink">如何发布资源</a>
            </span>
          </p>
          <p style="line-height: 1.80;" @click="Jump('跨库检索')">
            <span
              class="f_WPSOfficeÊOe¶¯Ae¿Â¼1"
              style="font-family: Calibri,Vectora,'Droid Sans','Open Sans',Frutiger,sans-serif;"
            >
              <a class="topiclink">4.</a>
            </span>
            <span class="f_WPSOfficeÊOe¶¯Ae¿Â¼1" style="font-family: 宋体;">
              <a class="topiclink">跨库检索</a>
            </span>
          </p>
          <p style="line-height: 1.80;" @click="Jump('如何检索问题')">
            <span
              class="f_WPSOfficeÊOe¶¯Ae¿Â¼2"
              style="font-family: Calibri,Vectora,'Droid Sans','Open Sans',Frutiger,sans-serif;"
            >
              <a class="topiclink">4.1.</a>
            </span>
            <span class="f_WPSOfficeÊOe¶¯Ae¿Â¼2" style="font-family: 宋体;">
              <a class="topiclink">如何检索问题</a>
            </span>
          </p>
          <p style="line-height: 1.80;" @click="Jump('基础功能')">
            <span
              class="f_WPSOfficeÊOe¶¯Ae¿Â¼1"
              style="font-family: Calibri,Vectora,'Droid Sans','Open Sans',Frutiger,sans-serif;"
            >
              <a class="topiclink">5.</a>
            </span>
            <span class="f_WPSOfficeÊOe¶¯Ae¿Â¼1" style="font-family: 宋体;">
              <a class="topiclink">基础功能</a>
            </span>
          </p>
          <p style="line-height: 1.80;" @click="Jump('评论')">
            <span
              class="f_WPSOfficeÊOe¶¯Ae¿Â¼2"
              style="font-family: Calibri,Vectora,'Droid Sans','Open Sans',Frutiger,sans-serif;"
            >
              <a class="topiclink">5.1.</a>
            </span>
            <span class="f_WPSOfficeÊOe¶¯Ae¿Â¼2" style="font-family: 宋体;">
              <a class="topiclink">评论</a>
            </span>
          </p>
          <p style="line-height: 1.80;" @click="Jump('回复评论')">
            <span
              class="f_WPSOfficeÊOe¶¯Ae¿Â¼2"
              style="font-family: Calibri,Vectora,'Droid Sans','Open Sans',Frutiger,sans-serif;"
            >
              <a class="topiclink">5...</a>
            </span>
            <span class="f_WPSOfficeÊOe¶¯Ae¿Â¼2" style="font-family: 宋体;">
              <a class="topiclink">回复评论</a>
            </span>
          </p>
          <p style="line-height: 1.80;" @click="Jump('点赞')">
            <span
              class="f_WPSOfficeÊOe¶¯Ae¿Â¼2"
              style="font-family: Calibri,Vectora,'Droid Sans','Open Sans',Frutiger,sans-serif;"
            >
              <a class="topiclink">5.3.</a>
            </span>
            <span class="f_WPSOfficeÊOe¶¯Ae¿Â¼2" style="font-family: 宋体;">
              <a class="topiclink">点赞</a>
            </span>
          </p>
          <p style="line-height: 1.80;" @click="Jump('收藏')">
            <span
              class="f_WPSOfficeÊOe¶¯Ae¿Â¼2"
              style="font-family: Calibri,Vectora,'Droid Sans','Open Sans',Frutiger,sans-serif;"
            >
              <a class="topiclink">5.4.</a>
            </span>
            <span class="f_WPSOfficeÊOe¶¯Ae¿Â¼2" style="font-family: 宋体;">
              <a class="topiclink">收藏</a>
            </span>
          </p>
          <p style="line-height: 1.80;" @click="Jump('分享')">
            <span
              class="f_WPSOfficeÊOe¶¯Ae¿Â¼2"
              style="font-family: Calibri,Vectora,'Droid Sans','Open Sans',Frutiger,sans-serif;"
            >
              <a class="topiclink">5.5.</a>
            </span>
            <span class="f_WPSOfficeÊOe¶¯Ae¿Â¼2" style="font-family: 宋体;">
              <a class="topiclink">分享</a>
            </span>
          </p>
          <p style="line-height: 1.80;">
            <span
              class="f_WPSOfficeÊOe¶¯Ae¿Â¼1"
              style="font-family: Calibri,Vectora,'Droid Sans','Open Sans',Frutiger,sans-serif;"
            >
              <a class="topiclink">6.</a>
            </span>
            <span class="f_WPSOfficeÊOe¶¯Ae¿Â¼1" style="font-family: 宋体;">
              <a class="topiclink">个人中心</a>
            </span>
          </p>
          <p style="line-height: 1.80;" @click="Jump('如何查看账单')">
            <span
              class="f_WPSOfficeÊOe¶¯Ae¿Â¼2"
              style="font-family: Calibri,Vectora,'Droid Sans','Open Sans',Frutiger,sans-serif;"
            >
              <a class="topiclink">6.1.</a>
            </span>
            <span class="f_WPSOfficeÊOe¶¯Ae¿Â¼2" style="font-family: 宋体;">
              <a class="topiclink">如何查看账单</a>
            </span>
          </p>
          <p style="line-height: 1.80;" @click="Jump('如何查看评论')">
            <span
              class="f_WPSOfficeÊOe¶¯Ae¿Â¼2"
              style="font-family: Calibri,Vectora,'Droid Sans','Open Sans',Frutiger,sans-serif;"
            >
              <a class="topiclink">6.2.</a>
            </span>
            <span class="f_WPSOfficeÊOe¶¯Ae¿Â¼2" style="font-family: 宋体;">
              <a class="topiclink">如何查看评论</a>
            </span>
          </p>
          <p style="line-height: 1.80;" @click="Jump('积分规则')">
            <span
              class="f_WPSOfficeÊOe¶¯Ae¿Â¼2"
              style="font-family: Calibri,Vectora,'Droid Sans','Open Sans',Frutiger,sans-serif;"
            >
              <a class="topiclink">6.3.</a>
            </span>
            <span class="f_WPSOfficeÊOe¶¯Ae¿Â¼2" style="font-family: 宋体;">
              <a class="topiclink">积分规则</a>
            </span>
          </p>
          <p style="line-height: 1.80;" @click="Jump('如何充值知识币')">
            <span
              class="f_WPSOfficeÊOe¶¯Ae¿Â¼2"
              style="font-family: Calibri,Vectora,'Droid Sans','Open Sans',Frutiger,sans-serif;"
            >
              <a class="topiclink">6.4.</a>
            </span>
            <span class="f_WPSOfficeÊOe¶¯Ae¿Â¼2" style="font-family: 宋体;">
              <a class="topiclink">如何充值知识币</a>
            </span>
          </p>
          <p style="line-height: 1.80;">
            <span
              class="f_WPSOfficeÊOe¶¯Ae¿Â¼2"
              style="font-family: Calibri,Vectora,'Droid Sans','Open Sans',Frutiger,sans-serif;"
            >
              <a class="topiclink">6.5.</a>
            </span>
            <span class="f_WPSOfficeÊOe¶¯Ae¿Â¼2" style="font-family: 宋体;">
              <a class="topiclink">如何查看我发布的</a>
            </span>
          </p>
          <p style="line-height: 1.80;">
            <span class="f_WPSOfficeÊOe¶¯Ae¿Â¼1">&nbsp;</span>
          </p>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  methods: {
    Jump(name) {
      let path = name == "智荟网" ? "help_list" : "/help_main";
      this.$router.push({
        path: path,
        query: {
          name: name
        }
      });
    }
  }
};
</script>
<style scoped>
p{
  cursor: pointer;
}
</style>