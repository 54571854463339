<template>
  <div>
    <!-- <div class="top">{{name}}</div> -->
    <!-- <v-list v-if="name=='智荟网'"></v-list> -->
    <div v-html="value" style="font-family: 宋体; font-size:15px"></div>
  </div>
</template>
<script>
import list from "@/views/academic-headlines/index/list.vue";
import { get_list } from "@/views/academic-headlines/index/help.js";
export default {
  data() {
    return {
      value: "",
      // name: ""
    };
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.query.name != from.query.name) {
        this.init();
      }
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.value = get_list(this.$route.query.name).value
      // this.name=this.$route.query.name
    //   .replace(
    //     /[\'\"\\\\]/g,
    //     ""
    //   ); //赋值
    }
  },
  components: {
    "v-list": list
  }
};
</script>
<style scoped>
.top {
  width: 100%;
  height: 50px;
  line-height: 50px;
  background-color: #8e9090;
  color: #ffffff;
  font-size: 30px;
  padding: 0 30px;
}
</style>